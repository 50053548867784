<template>
  <div>
    <aw-section-wrapper :has-padding="true">
      <template #content="{ contentEvents: { contentLoading } }">
        <client-only>
          <aw-tab-carousel
            :empty-placeholder="$awt('aw.cms.catalog.list.fallback_slide.text')"
            :main-title="needTitle ? $awt('aw.cms.catalog.list.main_title') : ''"
            tab-key="id"
            slide-key="id"
            :tabs="tabs"
            :breakpoints="breakpoints"
            :slides="slides"
            :is-carousel="isCarousel"
            :class="$style.tabCarousel"
            :has-title-padding="hasTitlePadding"
            @tab-opened="getCatalog(contentLoading, $event.newTabItem)"
          >
            <template #slide(all)(all)="{ slide }">
              <aw-catalog-card
                :key="slide.id"
                :catalog="slide"
                :analytics-measurable="analyticsMeasurable"
                :analytics-banner-interaction="analyticsBannerInteraction"
              />
            </template>
          </aw-tab-carousel>
        </client-only>
      </template>
    </aw-section-wrapper>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'pinia';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface.js';
  import otqs from '~~/common/utils/objectToQueryString';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  export default {
    name: 'AwCatalog',
    components: {
      AwCatalogCard: defineAsyncComponent(() => import('~~/shop/components/Page/Catalog/AwCatalogCard')),
      AwTabCarousel: defineAsyncComponent(() => import('~~/common/components/AwTabCarousel')),
      AwSectionWrapper: defineAsyncComponent(() => import('~~/common/components/Page/Common/AwSectionWrapper')),
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      isCarousel: {
        type: Boolean,
        default: true,
      },
      store: {
        type: Object,
        default: null,
      },
      needTitle: {
        type: Boolean,
        default: false,
      },
      breakpointsOverwrite: {
        type: Array,
        default: () => ([]),
      },
      hasTitlePadding: {
        type: Boolean,
        default: true,
      },
    },
    emits: ['is-empty'],
    data () {
      const tabs = [
        {
          id: 'current',
          tabTitle: '',
        },
      ];
      return {
        slides: Object.fromEntries(tabs.map(e => [e?.id, []])),
        tabs,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      breakpoints () {
        return this.breakpointsOverwrite.length ? this.breakpointsOverwrite : [
          {
            at: this.screenRange['desktop-medium-min'],
            count: 4,
          },
          {
            at: this.screenRange['tablet-min'],
            count: 3,
          },
          {
            at: true,
            count: 'auto',
          },
        ];
      },
    },
    methods: {
      async getCatalog (updateLoadingState, { id }) {
        this.selectedTabId = id;
        updateLoadingState(true);

        let params = {
          availability: id,
        };

        if (this.store?.id) {
          params = {
            ...params,
            storeCode: this.store.id,
          };
        }

        this.slides[id] = (await this.$api.$get(`catalog/list${otqs(params)}`));
        updateLoadingState(false);
        this.$emit('is-empty', this.slides[id].length === 0);
      },
    },
  };
</script>
<style module lang="scss" rel="stylesheet/scss">
.tabCarousel {
  --awTabCarouselParentMinHeight: 312px;
}

</style>
